
import store from "@/store/index";
import { Department, Work } from '@/model'
import { WorkController, DepartmentController } from '@/controller';
import { Button, IconButton, Checkbox, Card } from '@/ui';
import { defineComponent } from 'vue';

export default defineComponent({
    name: "WorkForm",
    components: { Button, IconButton, Checkbox, Card },
    data() {
        return{
            store,
            editMode: false,
            form:{
                uid: 0,
                title: "",
                number: "",
                defaultHourlyRate: 0,
                description: "",
                setupTime: 0,
                departments: [] as Array<number>
            },
            work: {} as Work | null,
            departments: [] as Array<Department>
        }
    },
    computed:{
        getProfileImageSrc() {
            return "";
        },
        departmentOptions() {
            const result = [] as Array<any>
            this.departments.forEach(d => {
                result.push({
                    'uid': d.uid,
                    'title': d.title,
                    'assigned': (this.form.departments.includes(d.uid))
                })
            })
            return result
        },
        allDepartmentsSelected(): boolean {
            return this.form.departments.length == this.departments.length
        }
    },
    created() {
        if (this.$route.params.id && this.$route.params.id != "0" ) {
            this.editMode = true;
            this.handleGetWork(this.$route.params.id);
        } else {
            this.editMode = false;
        }
        this.fetchDepartments()
    },
    methods:{
        handleGoBack() {
            this.$router.go(-1);
        },
        handleSubmitPrevent(){
            if (this.editMode) {
                this.handleSave();
            } else {
                this.handleCreate();
            }
        },
        handleDepartmentChange(department: any, value: number) {
            if (value) {
                this.form.departments.push(department.uid)
            } else {
                const idx = this.form.departments.indexOf(department.uid)
                if (idx>-1) this.form.departments.splice(idx,1)
            }
        },
        handleToggleSelectAll() {
            if (this.allDepartmentsSelected) {
                this.form.departments = []
            } else {
                this.form.departments = []
                this.departments.forEach(d => this.form.departments.push(d.getUid()))
            }
        },
        async handleGetWork(uid:any) {
            const res = await WorkController.getWorkById(uid);
            if (!res.error) {
                this.setFormData(res.work)
            } else {
                this.$notify(this.$t(res.error.message), { position: "right", type: "error" });
            }
        },
        async fetchDepartments() {
            const res = await DepartmentController.fetchDepartments();
            if(!res.error){
                this.departments = res.items;
            } else {
                this.$notify(this.$t(res.error.message), { position: "right", type: "error" });
            }
        },           
        async handleCreate() {
            const res = await WorkController.createWork(this.form);
            if (!res.error) {
                this.$notify(this.$t("success.workCreated"), { position: "top", type: "success" });
                this.$router.push("/administration/works");
            } else {
                this.$notify(this.$t(res.error.message), { position: "right", type: "error" });
            }
        },
        async handleSave() {
            const res = await WorkController.updateWork(this.form);
            if (!res.error) {
                this.form = res.work;
                this.$notify(this.$t("success.workUpdated"), { position: "top", type: "success" });
                this.$router.go(-1);
            } else {
                this.$notify(this.$t(res.error.message), { position: "right", type: "error" });
            }
        },
        setFormData(work: Work) {
            this.form.uid = work.getUid()
            this.form.title = work.getTitle()
            this.form.number = work.getNumber()
            this.form.defaultHourlyRate = work.getDefaultHourlyRate()
            this.form.description = work.getDescription()
            this.form.setupTime = work.getSetupTime()
            this.form.departments = []
            this.work = work
            this.work.getDepartments().forEach(d => this.form.departments.push(d.uid))

        }
    }
})
