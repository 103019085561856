import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "timi-administration-container timi-administration-container-work" }
const _hoisted_2 = { class: "form-layout" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col-6" }
const _hoisted_5 = { class: "timi-form-item col-6" }
const _hoisted_6 = ["placeholder"]
const _hoisted_7 = { class: "timi-form-item col-6" }
const _hoisted_8 = ["placeholder"]
const _hoisted_9 = { class: "timi-form-item col-6" }
const _hoisted_10 = { class: "timi-form-item col-6" }
const _hoisted_11 = { class: "timi-form-item" }
const _hoisted_12 = ["placeholder"]
const _hoisted_13 = { class: "col-6" }
const _hoisted_14 = { class: "timi-form-item" }
const _hoisted_15 = { class: "timi-form-item timi-form-item-action" }
const _hoisted_16 = { class: "row" }
const _hoisted_17 = { class: "form-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconButton = _resolveComponent("IconButton")!
  const _component_Card = _resolveComponent("Card")!
  const _component_Checkbox = _resolveComponent("Checkbox")!
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_IconButton, {
      icon: _ctx.store.getters.svgIcons.arrowbackward,
      class: "go-back-btn",
      label: _ctx.$t('button.back'),
      dense: true,
      onOnClick: _ctx.handleGoBack
    }, null, 8, ["icon", "label", "onOnClick"]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("form", {
        onSubmit: _cache[5] || (_cache[5] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.handleSubmitPrevent && _ctx.handleSubmitPrevent(...args)), ["prevent"]))
      }, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_Card, {
              title: _ctx.$t('label.general'),
              class: "transparent border flex"
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("label", null, _toDisplayString(_ctx.$t('work.name')), 1),
                  _withDirectives(_createElementVNode("input", {
                    type: "text",
                    placeholder: _ctx.$t('placeholder.title'),
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.form.title) = $event))
                  }, null, 8, _hoisted_6), [
                    [_vModelText, _ctx.form.title]
                  ])
                ]),
                _createElementVNode("div", _hoisted_7, [
                  _createElementVNode("label", null, _toDisplayString(_ctx.$t('label.number')), 1),
                  _withDirectives(_createElementVNode("input", {
                    type: "text",
                    placeholder: _ctx.$t('placeholder.number'),
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.form.number) = $event))
                  }, null, 8, _hoisted_8), [
                    [_vModelText, _ctx.form.number]
                  ])
                ]),
                _createElementVNode("div", _hoisted_9, [
                  _createElementVNode("label", null, _toDisplayString(_ctx.$t('label.defaultHourlyRate')), 1),
                  _withDirectives(_createElementVNode("input", {
                    type: "text",
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.form.defaultHourlyRate) = $event))
                  }, null, 512), [
                    [_vModelText, _ctx.form.defaultHourlyRate]
                  ])
                ]),
                _createElementVNode("div", _hoisted_10, [
                  _createElementVNode("label", null, _toDisplayString(_ctx.$t('label.setupTime')), 1),
                  _withDirectives(_createElementVNode("input", {
                    type: "text",
                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.form.setupTime) = $event))
                  }, null, 512), [
                    [_vModelText, _ctx.form.setupTime]
                  ])
                ]),
                _createElementVNode("div", _hoisted_11, [
                  _createElementVNode("label", null, _toDisplayString(_ctx.$t('label.description')), 1),
                  _withDirectives(_createElementVNode("textarea", {
                    type: "text",
                    rows: "3",
                    placeholder: _ctx.$t('placeholder.description'),
                    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.form.description) = $event))
                  }, null, 8, _hoisted_12), [
                    [_vModelText, _ctx.form.description]
                  ])
                ])
              ]),
              _: 1
            }, 8, ["title"])
          ]),
          _createElementVNode("div", _hoisted_13, [
            _createVNode(_component_Card, {
              title: _ctx.$t('label.workInDepartments'),
              class: "transparent border"
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_14, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.departmentOptions, (department) => {
                    return (_openBlock(), _createBlock(_component_Checkbox, {
                      value: department.assigned,
                      key: department.uid,
                      label: department.title,
                      onOnChange: (value) => _ctx.handleDepartmentChange(department, value)
                    }, null, 8, ["value", "label", "onOnChange"]))
                  }), 128))
                ]),
                _createElementVNode("div", _hoisted_15, [
                  _createVNode(_component_Button, {
                    text: (_ctx.allDepartmentsSelected ? _ctx.$t('button.deSelectAll') : _ctx.$t('button.selectAll')),
                    primary: true,
                    onOnClick: _ctx.handleToggleSelectAll
                  }, null, 8, ["text", "onOnClick"])
                ])
              ]),
              _: 1
            }, 8, ["title"])
          ])
        ]),
        _createElementVNode("div", _hoisted_16, [
          _createElementVNode("div", _hoisted_17, [
            (!_ctx.editMode)
              ? (_openBlock(), _createBlock(_component_Button, {
                  key: 0,
                  text: _ctx.$t('button.addWork'),
                  primary: true,
                  raised: true,
                  onOnClick: _ctx.handleCreate
                }, null, 8, ["text", "onOnClick"]))
              : (_openBlock(), _createBlock(_component_Button, {
                  key: 1,
                  text: _ctx.$t('button.save'),
                  primary: true,
                  raised: true,
                  onOnClick: _ctx.handleSave
                }, null, 8, ["text", "onOnClick"]))
          ])
        ])
      ], 32)
    ])
  ]))
}